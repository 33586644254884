<script setup lang="ts">
import { computed } from 'vue'
import Topbar from '@/layouts/Topbar.vue'
import { AuthStore } from '@/store/auth.store'
import { multiDocSelectedKey } from '@/utils/injectionKeys'
import { SelectedDocument } from '@/services/document.service'
import { MultiDocSelectedDocuments } from '@/utils/injectionKeys'
import { useMaxSpendLimitDialog } from '@/composables/useMaxSpendLimitDialog'
import LazyMaxSpendLimitErrorDialog from '@/components/MaxSpendLimitErrorDialog.vue'

const { layoutConfig, layoutState } = useLayout()
const { visible, toggle } = useLowCredits()
const { visible: maxSpendLimitVisible, toggle: maxSpendLimitToggle } =
    useMaxSpendLimitDialog()
const { $authStore } = useNuxtApp()
const authStore = $authStore as AuthStore

useHead({
    script: [
        {
            textContent: `!function(){let e=window.birdeatsbug=window.birdeatsbug||[];if(!e.initialize){if(e.invoked){window.console&&console.error&&console.error("birdeatsbug snippet included twice.");return}e.invoked=!0,e.methods=["setOptions","trigger","resumeSession","takeScreenshot","startRecording","stopRecording","stopSession","uploadSession","deleteSession",],e.factory=function(t){return function(){let s=Array.prototype.slice.call(arguments);return s.unshift(t),e.push(s),e}};for(let t=0;t<e.methods.length;t++){let s=e.methods[t];e[s]=e.factory(s)}e.load=function(){let e=document.createElement("script");e.type="module",e.async=!0,e.src="https://sdk.birdeatsbug.com/v2/core.js";let t=document.getElementsByTagName("script")[0]||document.body.firstChild;t.parentNode.insertBefore(e,t);let s=document.createElement("link");s.rel="stylesheet",s.type="text/css",s.href="https://sdk.birdeatsbug.com/v2/style.css";let r=document.querySelector('link[rel="stylesheet"]')||t;r.parentNode.insertBefore(s,r)},e.load(),window.birdeatsbug.setOptions({publicAppId:"98af192b-f84c-4bd3-a666-ba7720e1516f",ui:{defaultButton:{icon:!1},text:{defaultButton:"Feedback"},watermark:!1}})}}();`,
        },
    ],
})

const containerClass = computed(() => {
    return {
        'layout-light': layoutConfig.colorScheme.value === 'light',
        'layout-dim': layoutConfig.colorScheme.value === 'dim',
        'layout-dark': layoutConfig.colorScheme.value === 'dark',
        'layout-colorscheme-menu':
            layoutConfig.menuTheme.value === 'colorScheme',
        'layout-primarycolor-menu':
            layoutConfig.menuTheme.value === 'primaryColor',
        'layout-transparent-menu':
            layoutConfig.menuTheme.value === 'transparent',
        'layout-overlay': layoutConfig.menuMode.value === 'overlay',
        'layout-static': layoutConfig.menuMode.value === 'static',
        'layout-slim': layoutConfig.menuMode.value === 'slim',
        'layout-slim-plus': layoutConfig.menuMode.value === 'slim-plus',
        'layout-horizontal': layoutConfig.menuMode.value === 'horizontal',
        'layout-reveal': layoutConfig.menuMode.value === 'reveal',
        'layout-drawer': layoutConfig.menuMode.value === 'drawer',
        'layout-static-inactive':
            layoutState.staticMenuDesktopInactive.value &&
            layoutConfig.menuMode.value === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive.value,
        'layout-mobile-active': layoutState.staticMenuMobileActive.value,
        'p-input-filled': layoutConfig.inputStyle.value === 'filled',
        'p-ripple-disabled': !layoutConfig.ripple.value,
        'layout-sidebar-active': layoutState.sidebarActive.value,
        'layout-sidebar-anchored': layoutState.anchored.value,
    }
})

const selectedDocuments = ref<SelectedDocument[]>([])

const updateSelectedDocuments = (documents: SelectedDocument[]) => {
    selectedDocuments.value = documents
}

const multiDocSelectedDocuments: MultiDocSelectedDocuments = {
    selectedDocuments,
    updateSelectedDocuments,
}

provide(multiDocSelectedKey, multiDocSelectedDocuments)
</script>

<template>
    <div :class="['layout-container', { ...containerClass }]">
        <div class="layout-content-wrapper">
            <Topbar />
            <slot />
        </div>
        <div class="layout-mask"></div>
        <LowCreditDailog :showModal="visible" @update:display="toggle" />
        <OnboardingPopup
            v-if="authStore?.userData?.email"
            :email="authStore?.userData?.email"
        />
        <LazyMaxSpendLimitErrorDialog
            v-if="maxSpendLimitVisible"
            :visible="maxSpendLimitVisible"
            @update:visible="maxSpendLimitToggle"
        />
    </div>
</template>

<style>
.layout-container.layout-static .layout-content-wrapper {
    margin-left: 0;
    padding: 0;
    height: 100vh;
    margin-top: -7px;
    display: flex;
    flex-direction: column;
}
</style>

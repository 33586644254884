<script lang="ts" setup>
import { add, compareAsc } from 'date-fns'

const props = defineProps<{
    email: string
}>()

const [visible, toggle] = useToggle(false)

const preference = useLocalStorage<{
    joined: boolean
    remindLaterAt: null | Date
    dismissed: boolean
}>(`onboarding_popup_${props.email}`, {
    joined: false,
    remindLaterAt: null,
    dismissed: false,
})

onMounted(() => {
    // If the user haven't joined yet and not dismissed
    if (!preference.value.joined && !preference.value.dismissed) {
        // If the user has a remind later date
        if (preference.value.remindLaterAt) {
            if (
                compareAsc(
                    new Date(),
                    new Date(preference.value.remindLaterAt)
                ) === 1
            ) {
                preference.value.remindLaterAt = null
                preference.value.joined = false
                toggle()
                return
            }
        } else {
            // If the user has not joined yet and not dismissed show the popup
            toggle()
        }
    }
})

const scheduleMeeting = () => {
    preference.value.remindLaterAt = null
    preference.value.joined = true
    navigateTo(
        'https://tidycal.com/samueljunghenn/ai-legal-assistant-training-private',
        {
            open: { target: '_blank' },
        }
    )
    toggle()
}

const remindLater = () => {
    preference.value.remindLaterAt = add(new Date(), {
        days: 7,
    })
    preference.value.joined = false
    toggle()
}

const closeDialog = () => {
    preference.value = {
        ...preference.value,
        dismissed: true,
    }
    toggle(false)
}
</script>
<template>
    <Dialog
        :visible="visible"
        :closeOnEscape="true"
        position="bottomright"
        :draggable="false"
        :showHeader="false"
        @update:visible="closeDialog"
        :closable="true"
        :style="{ width: '320px', position: 'relative', bottom: '75px' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div class="flex justify-content-end mt-2">
            <Button
                @click="closeDialog"
                icon="pi pi-times"
                severity="secondary"
                text
                rounded
                aria-label="Cancel"
            />
        </div>
        <div class="flex flex-column justify-content-center">
            <div class="flex flex-column justify-content-center">
                <i class="pi pi-phone text-6xl text-green-500 mx-auto"></i>
                <h5>We Are Ready To Help You!</h5>
            </div>
            <p class="text-base">
                Book an onboarding call and we will help you get up to speed
                quickly.
            </p>
            <div class="flex justify-content-center gap-2">
                <Button
                    size="small"
                    class="text-sm px-2 py-2 p-button-outlined"
                    label="Remind me Later"
                    type="button"
                    @click="remindLater"
                />
                <Button
                    size="small"
                    class="text-sm px-2 py-1"
                    label="Join Kick Off Call"
                    type="button"
                    @click="scheduleMeeting"
                />
            </div>
        </div>
    </Dialog>
</template>

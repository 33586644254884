<script lang="ts" setup>
import { useMaxSpendLimitDialog } from '@/composables/useMaxSpendLimitDialog'
import { AppAbility } from '@/utils/ability'
import { useAbility } from '@casl/vue'

defineProps<{
    visible: boolean
}>()

defineEmits<{
    'update:visible': [value: boolean]
}>()

const { toggle } = useMaxSpendLimitDialog()
const router = useRouter()
const { can } = useAbility<AppAbility>()

const clickHandler = () => {
    toggle(false)
    router.push('/usage-limit')
}
</script>

<template>
    <Dialog
        :visible="visible"
        modal
        header=" "
        position="top"
        :draggable="false"
        @update:visible="$emit('update:visible')"
        :closable="true"
        :style="{ maxWidth: '600px', width: '100%' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div class="flex flex-column justify-content-center align-items-center">
            <div class="flex justify-content-center">
                <i class="pi pi-info-circle text-6xl text-orange-500"></i>
            </div>
            <h3 class="text-center text-2xl mt-4">
                Monthly Spend Limit Reached
            </h3>
            <template v-if="can('manage', 'Subscription')">
                <p class="text-center text-lg">
                    Your organisation has reached the monthly account limit.
                    <br />
                    Please increase the monthly limit on the usage limits page
                    link below.
                    <br />
                    <NuxtLink @click="clickHandler" :to="`/usage-limit`">
                        {{ $config.public.appUrl }}/usage-limit
                    </NuxtLink>
                </p>
            </template>
            <template v-else>
                <p class="text-center text-lg">
                    Your organisation has reached the monthly account limit.
                    <br />
                    Please let the admin on your account know they need to
                    increase the monthly limit on the usage limits page link
                    below.
                    <br />
                    <NuxtLink @click="clickHandler" :to="`/usage-limit`">
                        {{ $config.public.appUrl }}/usage-limit
                    </NuxtLink>
                </p>
            </template>
        </div>
    </Dialog>
</template>
